* { margin:0px; padding:0px; border:none; outline:none; @include prefix (box-sizing, border-box); -webkit-tap-highlight-color: transparent; }
html { @include prefix (text-size-adjust, none); }
body { font-size: 16px; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; color:$theme-black; font-family: $fonts; }
img { max-width: 100%; vertical-align: middle; display: inline-block; }
a { text-decoration: none; @include prefix( transition, all 0.3s ease-in-out);
    &:hover,
    &:focus { @extend a; }
}
button { outline: 0; @include prefix( transition, all 0.3s ease-in-out);
    &:hover,
    &:focus { @extend button; }
}
h1, h2, h3, h4, h5, h6, strong, b { margin: 0; font-weight: normal; line-height: normal; letter-spacing: 0; }
.text-white {
  h1, h2, h3, h4, h5, h6, strong, b, p, ul, ol, li { color: $white; }
}
.text-gray {
  h1, h2, h3, h4, h5, h6, strong, b, p, ul, ol, li, .timer { color: $color1; }
}
p { margin: 0; line-height: normal; }
ul, ol { list-style: none; margin: 0; padding: 0; }
svg { fill: currentColor; max-width: 100%; vertical-align: middle; }
div,aside,figure,figcaption, label { margin: 0; }
%transition { @include prefix( transition, all 0.3s ease-in-out); }
input,textarea,select,header{ @include prefix (appearance, none); }
select { background-repeat: no-repeat; background-position: 95% 50%; background-size: 12px; cursor: pointer; font-family: $fonts-sf; font-weight: 300; font-size: 16px;color: #252425;}
option { font-family: $fonts-sf; font-weight: 500; }
// ::-moz-selection { background: #389dab; color: $white; }
// ::selection { background: #389dab; color: $white; }
// ::-webkit-input-placeholder {color: $color2;opacity: 1;}
// :-moz-placeholder { color: $color2;opacity: 1;}
// ::-moz-placeholder { color: $color2;opacity: 1;}
// :-ms-input-placeholder {color: $color2;opacity: 1;}