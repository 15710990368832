@import "mixin";
// Color Variable
$theme-color: #761c35;
$white: #ffffff;
$black: #000000;

// black
$theme-black: #252425;
$theme-black2: #292929;
$theme-black3: #939393;
$theme-black4: #646464;
$theme-black5: #414141;

// Gray
$color1:#F7F7F7;
$color2:#778288;
$color3:#E8E6E6;
$color5:#E2F1F9;

$success: #089B55;
$danger: #E5664B;
$warning: #E5B64B;
$info: #448FB8;

// media quary
$xl: 1400px;
$lg: 1199px;
$md: 991px;
$sm: 767px;
$xs: 575px;
$mbv: 412px;
$xxs: 359px;

// fonts
$fonts : 'Avenir';
$fonts-light: 'Avenir-Light';
$fonts-regular: 'Avenir';
$fonts-medium: 'Avenir-Medium';
$fonts-extra: 'Avenir-Heavy';
$fonts-black: 'Avenir-Black';
$fonts-sf: 'SF Pro Display';