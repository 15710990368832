@import "variable";
@import "mixin";
@import "reset";
@import "col";

// Component
@import "component/flex";
@import "component/header";
@import "component/footer";

// Page

@import "common";

.app-main { margin: 63px 0 0 0; }

.explrtopic {
  padding: 70px 0 50px 0;
  .slick-slider { max-width: 100%; margin: 0 auto; }
  .slick-list { position: relative; padding: 0 !important;
    // &::before { content: ""; position: absolute; right: -50%; top: -30%; height: 250px; width: 50%; -webkit-box-shadow: -100px 0px 50px 0px rgba(255, 255, 255, 0.9); -moz-box-shadow: -100px 0px 50px 0px rgba(255, 255, 255, 0.9); box-shadow: -100px 0px 50px 0px rgba(255, 255, 255, 0.9); z-index: 11; }
  }
  &.resource {
    .slick-slider { max-width: 100%; margin: 0 -15px;}
    .slick-list { padding: 0 !important; }
  }
}
.explrtopic-title { text-align: center; font-size: 50px; font-family: $fonts-black; margin: 0 0 50px; }
.explrtopic-list { margin: 0 7px;
  a { border: 1px solid $theme-black; border-radius: 30px; padding: 6px 15px; display: inline-block; margin-right: 0; font-size: 14px; color: $theme-black; font-family: $fonts-sf; font-weight: 400;
    &:hover { background: $theme-black; color: $white; }
  }
}
.slidertitle {
  // display: flex;
  // justify-content: spac1e-between;
  position: relative;
  margin: 0 0 20px;
  h3 { font-size: 18px; background: $theme-color; color: $white; color: $white; font-family: 'Roboto', sans-serif; padding: 4px 50px 4px 15px; display: inline-block; }
  a{
    position: absolute;
    top: 7px;
    right: 46px;
    /* margin-top: 3px; */
    font-family: 'Roboto', sans-serif !important;
    display: inline-block;
    width: auto !important;
  }
}
.slider-seperator{
  margin-top: 30px;
  margin-bottom: -30px;
  hr {border:0.1px solid #F2F2F2; line-height:10px ;}
}
.getstarted {
  margin: 0 0 100px;
  .slick-list { margin: 0 -10px; position: relative; 
    // &::before { content: ""; position: absolute; right: -50%; top: -30%; height: 350px; width: 50%; -webkit-box-shadow: -100px 0px 50px 0px rgba(255, 255, 255, 0.9); -moz-box-shadow: -100px 0px 50px 0px rgba(255, 255, 255, 0.9); box-shadow: -100px 0px 50px 0px rgba(255, 255, 255, 0.8); z-index: 11; }
  }
  .slick-slide { padding: 10px; }
  a { font-size: 14px; color: $theme-black2; font-family: $fonts-extra; padding: 0; display: inline-block; width: 100%; }
  .started-info { text-align: left; padding: 0; font-family: $fonts-sf; font-weight: 600; }
  .started-post { font-size: 14px; color: $theme-black3; font-weight: 300; padding: 5px 0; }
  .started-price { font-size: 17px; color: $theme-black2; font-weight: 700; }
}
.get-started-box { width: 100%; padding: 0;
  .started-slide-img { border-radius: 12px; overflow: hidden; position: relative; padding-top: 100%; @include prefix(box-shadow, 0px 4px 10px rgba(0, 0, 0, 0.22)); margin: 10px 0;
    img { position: absolute;left: 0;right: 0;top: 0;bottom: 0;width: 100%;height: 100%;object-fit: cover;}
  }
  .view-all-list{
  border-radius: 12px;
    overflow: hidden;
    position: relative;
    padding-top: 100%;
    @include prefix(box-shadow, 0px 4px 10px rgba(0, 0, 0, 0.22));
    margin: 10px 0;
      
    img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 70px;
        height: 50px;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
      }
  }

}
.isbannerstarted {
  .get-started-box {
    .started-slide-img { padding-top: 50%; }
         .view-all-list {
           padding-top: 50%;
         }
  }
}

.swal-overlay--show-modal {
  .swal-modal { padding: 20px; }
  .swal-footer { padding-bottom: 0; text-align: center; }
}
.swal-title{color: $theme-color; font-size: 21px; text-align: center; line-height: 32px; }
.swal-text { color: $theme-color; font-size: 21px; text-align: center; line-height: 32px; }
.swal-icon { display: none; }
.swal-button--confirm { background-color: #761c35; color: #fff; border: none; box-shadow: none; border-radius: 5px; font-weight: 600; font-size: 14px; padding: 10px 24px; margin: 0; cursor: pointer; }
.swal-button--confirm:hover { background-color: #761c35 !important; }


@include responsive(lg) {
  .explrtopic {
    padding: 35px 0 50px 0;
  }
  .explrtopic-title {
    margin: 0 0 30px;
    font-size: 42px;
  }
}

@include responsive(md) {
  .app-main { margin: 57px 0 0 0; }
   .slider-seperator{
    margin-top:5;
    margin-bottom: 0;
  }
  .slidertitle {
    a { right: 0; }
  }
}

@include responsive(sm) {
  .explrtopic {
    padding: 20px 0 30px 0;
  }
  .explrtopic-title {
    margin: 0 0 15px;
    font-size: 32px;
  }
  .slider-seperator{
    margin-top:0;
    margin-bottom: 5;
  }
}

@include responsive(xs) {
  
}

@include responsive(mbv) {
  .slidertitle {
    h3 { font-size: 16px; padding: 4px 20px 4px 10px; }
  }
  .getstarted {
    .slick-next, .slick-prev { top: -45px; }
  }
}

@include responsive(xxs) {
  .slidertitle {
    h3 { font-size: 14px; padding: 4px 10px;}
  }
}


.no-data-search{ img {display: none;}}